


































































































































































































































































































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      intervalId: 0,
    }
  },
  watch: {
    // 画面遷移を監視する
    $route() {
      // スクロールメニューの中央位置を調整する
      this.intervalId = window.setInterval(this.adjustScrollMenu, 200)
    },
  },
  mounted() {
    // スマホ・タブレットの場合はCSS「:hover」を全て削除する
    this.$$removeCssHover()
  },
  methods: {
    /**
     * メニュー選択時に選択されたメニューを中央に移動する（スマホ）
     * @return {void}
     */
    adjustScrollMenu(): void {
      clearInterval(this.intervalId)
      if (window.innerWidth <= this.$$const.DEVICE.MOBILE_WIDTH) {
        const menu = document.querySelector('.md-menu')
        if (!menu) {
          return
        }
        const items = document.querySelectorAll('[role="menuitem"]')
        if (!items) {
          return
        }
        let itemX = 0
        for (let i = 0; i < items.length; i++) {
          if (items[i].classList.contains('is-active')) {
            const item = items[i] as HTMLElement
            itemX = item.getBoundingClientRect().left + item.offsetWidth / 2
            break
          }
        }
        if (menu.clientWidth < menu.scrollWidth) {
          const posDiff =
            itemX - (menu.getBoundingClientRect().left + menu.clientWidth / 2)
          menu.scrollLeft = menu.scrollLeft + posDiff
        }
      }
    },

    /**
     * 表示するページを変更する
     * @return {void}
     */
    changePage(path: string): void {
      // メンテナンス中か確認する
      if (this.$$checkMaintenance(path)) {
        alert(this.$t('message.maintenance'))
        return
      }

      // ページのアクセス権を確認する
      if (!this.$$checkAccessRights(path)) {
        alert(this.$t('message.noAccessRights'))
        return
      }

      // ページ遷移
      this.$router.push(path)
    },
  },
})
