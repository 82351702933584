

























































































































































































import Vue from 'vue'

export default Vue.extend({
  props: {
    parentXKeyword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      xKeyword: '',
      headerMenuSelected: false,
      logoSrc: '/img/' + this.$config.DEFAULT_LOG,
    }
  },
  watch: {
    // 親コンポーネントの横断検索キーワードの更新を監視する
    parentXKeyword(newXKeyword) {
      this.xKeyword = newXKeyword
    },
    // 画面遷移を監視する
    $route() {
      // 検索サービス毎のロゴに変更する
      this.changeLogo()
    },
  },
  created() {
    // 検索サービス毎のロゴに変更する
    this.changeLogo()
  },
  mounted() {
    // エラーメッセージは日本語
    this.$$setValidationLocale('ja')
  },
  methods: {
    /**
     * 検索サービス毎のロゴに変更する
     * @return {void}
     */
    changeLogo(): void {
      let logo = this.$config.DEFAULT_LOG
      if (this.$route.path === '/kiji/discovery/') {
        logo = this.$config.EXTERNAL_LOGO
      } else {
        const svSname = String(this.$$getLoginInfo('SV_SNAME'))
        if (this.$config.SERVICE_LOGO[svSname] !== undefined) {
          logo = this.$config.SERVICE_LOGO[svSname]
        }
      }
      this.logoSrc = '/img/' + logo
    },

    /**
     * Enterキー押下して検索する
     * @params {any} e
     * @return {void}
     */
    searchByEnterKey(e: any): void {
      if (e.keyCode === 13) {
        this.search()
      }
    },

    /**
     * リスト検索する
     * @return {Promise<void>}
     */
    async search(): Promise<void> {
      // バリデーション
      const errorMessage = await this.$$validate()
      if (errorMessage !== '') {
        alert(errorMessage)
        return
      }

      // リスト検索（横断検索）を実行するためTOP画面へ遷移する
      this.headerMenuSelected = false
      this.$router.push({ name: 'top', params: { xKeyword: this.xKeyword } })
    },

    /**
     * ログアウトする
     * @return {Promise<void>}
     */
    async logout(): Promise<void> {
      // ローディング画面を表示する
      this.$$showLoading()

      // ログアウトする
      await this.$$logout()

      // TOPへリダイレクト
      if (
        (this.$$getLoginInfo('L_LOGIN_CNO') === '6' ||
          this.$$getLoginInfo('L_LOGIN_CNO') === '7') &&
        this.$$getLoginInfo('RL_LOGIN_CNO') === '1'
      ) {
        location.href = '/devicelogout/'
      } else {
        location.href = '/logout/'
      }
    },
  },
})
