import { $$const } from '@/plugins/const'
import { DeviceError } from '@/plugins/exception'
import { isSpDevice } from '@/plugins/device'

export default function (): void {
  // スマホ版サイトか判定
  if ($$const.API.domainNm.match(/^xsearchsp/)) {
    // スマホデバイスでなければエラー
    if (isSpDevice() === false) {
      throw new DeviceError()
    }
  }
}
