
















import Vue from 'vue'
import { getLoginInfo } from '@/plugins/user'

export default Vue.extend({
  middleware: ['auth'],
  data() {
    return {
      xKeyword: '',
      authFlg: false,
    }
  },
  mounted() {
    // パスワード変更フラグが「1: 初回・再発行」or「2: 年度切替」の場合はパスワード変更画面へ
    if (
      getLoginInfo('LK_PASSCHG_FLAG') === '1' ||
      getLoginInfo('LK_PASSCHG_FLAG') === '2'
    ) {
      if (
        (getLoginInfo('L_LOGIN_CNO') === '6' ||
          getLoginInfo('L_LOGIN_CNO') === '7') &&
        getLoginInfo('RL_LOGIN_CNO') === '1'
      ) {
        this.$router.push('/devicepasswd/')
      } else {
        this.$router.push('/passwd/')
      }
      return
    }

    // 認証失敗の場合はページを表示しない
    if (getLoginInfo('L_LOGINID') !== false) {
      this.authFlg = true
    }

    // グローバルなオブジェクトにハンドラ追加
    this.$nuxt.$on('updateXKeyword', this.setXKeyword)
  },
  methods: {
    setXKeyword(xKeyword: string): void {
      this.xKeyword = xKeyword
    },
  },
})
