


























import Vue from 'vue'

export default Vue.extend({
  props: {},
  data() {
    return {
      logoSrc: '/img/' + this.$config.DEFAULT_LOG,
    }
  },
  watch: {
    // 画面遷移を監視する
    $route() {
      // 検索サービス毎のロゴに変更する
      this.changeLogo()
    },
  },
  created() {
    // 検索サービス毎のロゴに変更する
    this.changeLogo()
  },
  mounted() {
    // エラーメッセージは日本語
    this.$$setValidationLocale('ja')
  },
  methods: {
    /**
     * 検索サービス毎のロゴに変更する
     * @return {void}
     */
    changeLogo(): void {
      let logo = this.$config.DEFAULT_LOG
      if (this.$route.path === '/kiji/discovery/') {
        logo = this.$config.EXTERNAL_LOGO
      } else {
        const svSname = String(this.$$getLoginInfo('SV_SNAME'))
        if (this.$config.SERVICE_LOGO[svSname] !== undefined) {
          logo = this.$config.SERVICE_LOGO[svSname]
        }
      }
      this.logoSrc = '/img/' + logo
    },
  },
})
