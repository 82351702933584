import { DangoRequest, DangoResponse } from '@/types/dango'
import { Context } from '@nuxt/types'
import { $$const } from '@/plugins/const'
import { login } from '@/plugins/dangoapi'
import {
  StopSvsError,
  MaintenanceError,
  SessionOverError,
} from '@/plugins/exception'

export default async function (context: Context): Promise<void> {
  const query = document.getElementById('query') as HTMLInputElement
  let loginId = ''
  let passWd = ''
  let loginOyaId = ''
  if (query) {
    const items = query.value.split('&')
    for (const item of items) {
      const row = item.split('=')
      if (row[0] === 'loginId') {
        loginId = row[1]
      } else if (row[0] === 'passWd') {
        passWd = row[1]
      } else if (row[0] === 'loginOyaId') {
        loginOyaId = row[1]
      }
    }
  }

  if (loginId !== '' && passWd !== '') {
    // クエリを初期化
    const query: DangoRequest.loginIF = {
      domainNm: $$const.API.domainNm,
      loginId,
      passWd,
    }

    // 親子孫ID対応
    if (loginOyaId !== '') {
      query.loginOyaId = loginOyaId
    }

    // APIをコールする
    const res: DangoResponse.loginIF = await login(query)
    console.log(context)
    if (!res || res.status === false) {
      // システム or サービスがメンテナンス中であればエラー
      if (res.errCode === 'MSTEC010003' || res.errCode === 'MSTEC010010') {
        throw new MaintenanceError()
      }
      // 契約 or 検索サービス停止中であればエラー
      if (res.errCode === 'MSTEC010008' || res.errCode === 'MSTEC010009') {
        console.log('StopSvsError')
        throw new StopSvsError()
      }
      // セッションオーバーであればエラー
      if (res.errCode === 'MSTEC010013') {
        throw new SessionOverError()
      }
      alert(res.errMsg)
    } else {
      context.redirect({ name: 'top' })
    }
  }
}
