import { DangoResponse } from '@/types/dango'
import { $$const } from '@/plugins/const'

/**
 * @type {DangoResponse.loginInfo} ユーザー情報
 */
let loginInfo: DangoResponse.loginInfo | undefined

/**
 * ユーザー情報を保存する
 * @params {DangoResponse.loginInfo} loginInfo
 * @return {void}
 */
export const storeLoginInfo = (info: DangoResponse.loginInfo): void => {
  loginInfo = info
}

/**
 * ユーザー情報を取得する
 * @params {keyof DangoResponse.loginInfo} key
 * @return {number|string|boolean}
 */
export const getLoginInfo = (
  key: keyof DangoResponse.loginInfo
): number | string | boolean => {
  if (loginInfo === undefined) {
    return false
  }

  const info = loginInfo[key]
  if (typeof info === 'number' || typeof info === 'string') {
    return info
  } else {
    return false
  }
}

/**
 * メンテナンス中か確認する
 * @params {string} path
 * @return {boolean}
 */
export const checkMaintenance = (path: string): boolean => {
  console.log('func checkMaintenance:' + path)
  // 記事検索
  if (path.match(/^\/kiji\//)) {
    if (getLoginInfo('MAINTE_ASAHI') === 'Y') {
      return true
    }
  }

  // 縮刷版
  if (path.match(/^\/shimen\//)) {
    if (getLoginInfo('MAINTE_SHIMEN') === 'Y') {
      return true
    }
  }

  // 英文ニュース
  if (path.match(/^\/english\//)) {
    if (getLoginInfo('MAINTE_EIBUN') === 'Y') {
      return true
    }
  }

  // 知恵蔵
  if (path.match(/^\/chiezo\//)) {
    if (getLoginInfo('MAINTE_CHIEZO') === 'Y') {
      return true
    }
  }

  // 人物
  if (path.match(/^\/jimbutsu\//)) {
    if (getLoginInfo('MAINTE_JINBUTU') === 'Y') {
      return true
    }
  }

  // アサヒグラフ
  if (path.match(/^\/graph\//)) {
    if (getLoginInfo('MAINTE_GRAPH') === 'Y') {
      return true
    }
  }

  return false
}

/**
 * ページのアクセス権を確認する
 * @params {string} path
 * @return {boolean}
 */
export const checkAccessRights = (path: string): boolean => {
  console.log('func checkAccessRights:' + path)
  // 記事検索
  if (
    path === '/kiji/' ||
    path === '/kiji/list_print/' ||
    path === '/kiji/detail/' ||
    path === '/kiji/detail_list/' ||
    path === '/kiji/image/' ||
    path === '/kiji/discovery/'
  ) {
    if (
      (getLoginInfo('SV_DATA_ASAHI') !== 'Y' &&
        getLoginInfo('SV_DATA_AERA') !== 'Y' &&
        getLoginInfo('SV_DATA_WEEKLY') !== 'Y') ||
      getLoginInfo('L_OPT_ASAHI') !== 'Y'
    ) {
      return false
    }
    if (path === '/kiji/image/' && getLoginInfo('L_OPT_KIRI') !== 'Y') {
      return false
    }
  }

  // 縮刷版（本紙）
  if (
    path === '/shimen/' ||
    path === '/shimen/date/' ||
    path === '/shimen/seireki/' ||
    path === '/shimen/kw_print_list/' ||
    path === '/shimen/detail/'
  ) {
    if (
      (getLoginInfo('SV_DATA_SHIMEN') !== 'Y' ||
        getLoginInfo('L_OPT_SHIMEN') !== 'Y') &&
      (getLoginInfo('SV_DATA_SHIMEN_MEIJI') !== 'Y' ||
        getLoginInfo('L_OPT_SHIMEN_MEIJI') !== 'Y') &&
      (getLoginInfo('SV_DATA_SHIMEN_SENZEN') !== 'Y' ||
        getLoginInfo('L_OPT_SHIMEN_SENZEN') !== 'Y') &&
      (getLoginInfo('SV_DATA_SHIMEN_SENGO') !== 'Y' ||
        getLoginInfo('L_OPT_SHIMEN_SENGO') !== 'Y') &&
      (getLoginInfo('SV_DATA_SHIMEN_HEISEI') !== 'Y' ||
        getLoginInfo('L_OPT_SHIMEN_HEISEI') !== 'Y')
    ) {
      return false
    }
  }

  // 縮刷版（地域面）
  if (path === '/shimen/region/') {
    if (
      getLoginInfo('SV_DATA_SHIMEN_REGION') !== 'Y' ||
      getLoginInfo('L_OPT_SHIMEN_REGION') !== 'Y'
    ) {
      return false
    }
  }

  // 地域面検索β版
  if (path === '/shimen/region2/') {
    if (
      getLoginInfo('SV_DATA_OCR_REGION') !== 'Y' ||
      getLoginInfo('L_OPT_OCR_REGION') !== 'Y'
    ) {
      return false
    }
  }

  // 縮刷版（外地版）
  if (path === '/shimen/gaichi/') {
    if (
      getLoginInfo('SV_DATA_SHIMEN_GAICHI') !== 'Y' ||
      getLoginInfo('L_OPT_SHIMEN_GAICHI') !== 'Y'
    ) {
      return false
    }
  }

  // 縮刷版（サムネイル・PDF等の共通部分）
  if (
    path === '/shimen/thumbnail/' ||
    path === '/shimen/pdf/' ||
    path === '/shimen/print_pdf/' ||
    path === '/shimen/crop_image/' ||
    path === '/shimen/crop_result/' ||
    path === '/shimen/date_print_list/'
  ) {
    if (
      ((getLoginInfo('L_OPT_SHIMEN') !== 'Y' &&
        getLoginInfo('L_OPT_SHIMEN_MEIJI') !== 'Y' &&
        getLoginInfo('L_OPT_SHIMEN_SENZEN') !== 'Y' &&
        getLoginInfo('L_OPT_SHIMEN_SENGO') !== 'Y' &&
        getLoginInfo('L_OPT_SHIMEN_HEISEI') !== 'Y') ||
        getLoginInfo('SV_DATA_SHIMEN') !== 'Y') &&
      (getLoginInfo('SV_DATA_SHIMEN_REGION') !== 'Y' ||
        getLoginInfo('L_OPT_SHIMEN_REGION') !== 'Y') &&
      (getLoginInfo('SV_DATA_SHIMEN_GAICHI') !== 'Y' ||
        getLoginInfo('L_OPT_SHIMEN_GAICHI') !== 'Y')
    ) {
      return false
    }
  }

  // 知恵蔵
  if (path.match(/^\/chiezo\//)) {
    if (
      getLoginInfo('SV_DATA_CHIEZO') !== 'Y' ||
      getLoginInfo('L_OPT_CHIEZO') !== 'Y'
    ) {
      return false
    }
  }

  // 英文ニュース
  if (path.match(/^\/english\//)) {
    if (
      getLoginInfo('SV_DATA_CON3') !== 'Y' ||
      getLoginInfo('L_OPT_CON3') !== 'Y'
    ) {
      return false
    }
  }

  // 人物
  if (path.match(/^\/jimbutsu\//)) {
    if (
      getLoginInfo('SV_DATA_JINBUTU') !== 'Y' ||
      getLoginInfo('L_OPT_JINBUTU') !== 'Y'
    ) {
      return false
    }
  }

  // アサヒグラフ
  if (
    path === '/graph/' ||
    path === '/graph/pdf/' ||
    path === '/graph/print_list/' ||
    path === '/graph/print_pdf/' ||
    path === '/graph/thumbnail/'
  ) {
    if (
      getLoginInfo('SV_DATA_CON1') !== 'Y' ||
      getLoginInfo('L_OPT_CON1') !== 'Y'
    ) {
      return false
    }
  }

  // 歴史写真
  if (path.match(/^\/history\//)) {
    if (
      getLoginInfo('SV_DATA_PHOTO') !== 'Y' ||
      getLoginInfo('L_OPT_PHOTO') ===
        $$const.API_RESPONSE.L_OPT_PHOTO.INAVAILABLE
    ) {
      return false
    }
  }

  return true
}

/**
 * サービスの権限を確認する
 * @params {string} path
 * @return {boolean}
 */
export const checkSvData = (key: string): boolean => {
  if (key === 'ASAHI_SHIMBUN' || key === 'ASAHI_SHIMBUN_DIGITAL') {
    if (getLoginInfo('SV_DATA_ASAHI') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'AERA') {
    if (getLoginInfo('SV_DATA_AERA') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'SHUKAN_ASAHI') {
    if (getLoginInfo('SV_DATA_WEEKLY') === 'Y') {
      return true
    } else {
      return false
    }
  }

  if (key === 'MEIJI') {
    if (getLoginInfo('SV_DATA_SHIMEN_MEIJI') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'SENZEN') {
    if (getLoginInfo('SV_DATA_SHIMEN_SENZEN') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'SENGO') {
    if (getLoginInfo('SV_DATA_SHIMEN_SENGO') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'HEISEI') {
    if (getLoginInfo('SV_DATA_SHIMEN_HEISEI') === 'Y') {
      return true
    } else {
      return false
    }
  }
  return false
}

/**
 * ログインの権限を確認する
 * @params {string} path
 * @return {boolean}
 */
export const checkLoginData = (key: string): boolean => {
  if (key === 'MEIJI') {
    if (getLoginInfo('L_OPT_SHIMEN_MEIJI') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'SENZEN') {
    if (getLoginInfo('L_OPT_SHIMEN_SENZEN') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'SENGO') {
    if (getLoginInfo('L_OPT_SHIMEN_SENGO') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'HEISEI') {
    if (getLoginInfo('L_OPT_SHIMEN_HEISEI') === 'Y') {
      return true
    } else {
      return false
    }
  }
  return false
}

/**
 * バージョンをチェックする
 * @params {string} version
 * @return {boolean}
 */
export const checkVersion = (version: string): boolean => {
  let svVersion = getLoginInfo('SV_VERSION')
  if (typeof svVersion !== 'string') {
    svVersion = ''
  }
  const a = svVersion.split('.')
  const b = version.split('.')
  let ia = 0
  let ib = 0
  const limit = Math.max(a.length, b.length)
  for (let i = 0; i < limit; i++) {
    ia = parseInt(a[i]) || 0
    ib = parseInt(b[i]) || 0
    if (ia !== ib) {
      break
    }
  }
  if (ia <= ib) {
    return true
  } else {
    return false
  }
}
