var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"l-aside"},[_c('div',{staticClass:"md-menu-box"},[_c('ul',{staticClass:"md-menu md-menu--vertical",attrs:{"role":"menubar"}},[_c('li',{staticClass:"md-menu__item is-top",class:[_vm.$route.path === '/top/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[(_vm.$$getLoginInfo('ASA_SEARCH_FLAG') !== 'Y')?[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/top/')}}},[_c('span',[_vm._v(_vm._s(_vm.$t('menuLabel.top')))])])]:_vm._e(),_vm._v(" "),(_vm.$$getLoginInfo('ASA_SEARCH_FLAG') === 'Y')?[_c('a',{attrs:{"href":"JavaScript:void(0)"}},[_c('span',[_vm._v(_vm._s(_vm.$t('menuLabel.top')))])])]:_vm._e()],2),_vm._v(" "),(
          _vm.$$getLoginInfo('L_TAB_ASAHI') === 'Y' ||
          _vm.$$checkAccessRights('/kiji/')
        )?_c('li',{staticClass:"md-menu__item",class:[
          _vm.$route.path === '/kiji/' || _vm.$route.path === '/kiji/discovery/'
            ? 'is-active'
            : '' ],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/kiji/')}}},[(_vm.$i18n.locale === 'ja')?_c('ul',{staticClass:"md-menu__with-year"},[_c('li',{staticClass:"md-menu__with-year--main"},[_vm._v("\n              "+_vm._s(_vm.$t('menuLabel.kiji'))+"\n            ")]),_vm._v(" "),_c('li',{staticClass:"md-menu__with-year--sub"},[_vm._v("\n              "+_vm._s(_vm.$t('menuLabel.kiji_sub'))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.$i18n.locale === 'en')?_c('ul',{staticClass:"md-menu__with-year"},[_c('li',{staticClass:"md-menu__with-year--main"},[_vm._v("\n              "+_vm._s(_vm.$t('menuLabel.kiji'))+" "+_vm._s(_vm.$t('menuLabel.kiji_sub'))+"\n            ")]),_vm._v(" "),_c('li',{staticClass:"md-menu__with-year--sub"})]):_vm._e()])]):_vm._e(),_vm._v(" "),(
          _vm.$$getLoginInfo('L_TAB_SHIMEN') === 'Y' ||
          _vm.$$getLoginInfo('L_TAB_REGION') === 'Y' ||
          _vm.$$getLoginInfo('L_TAB_GAICHI') === 'Y' ||
          _vm.$$checkAccessRights('/shimen/') ||
          _vm.$$checkAccessRights('/shimen/region/') ||
          _vm.$$checkAccessRights('/shimen/region2/') ||
          _vm.$$checkAccessRights('/shimen/gaichi/')
        )?_c('li',{staticClass:"md-menu__item",attrs:{"role":"menuitem","ariahaspopup":"true","aria-expanded":"true"}},[_c('div',{staticClass:"md-submenu__title",class:[_vm.$route.path === '/shimen/' ? 'is-active' : '']},[(
              _vm.$$getLoginInfo('L_TAB_SHIMEN') === 'Y' ||
              _vm.$$checkAccessRights('/shimen/')
            )?_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/shimen/')}}},[_c('div',{staticClass:"md-submenu__title__parent"},[_c('ul',{staticClass:"md-menu__with-year"},[_c('li',{staticClass:"md-menu__with-year--main"},[_vm._v("\n                  "+_vm._s(_vm.$t('menuLabel.shimen'))+"\n                ")]),_vm._v(" "),_c('li',{staticClass:"md-menu__with-year--sub"},[_vm._v("\n                  "+_vm._s(_vm.$t('menuLabel.shimen_sub'))+"\n                ")])])]),_vm._v(" "),_c('div',{staticClass:"md-submenu__title__child is-long-word--menu"},[_vm._v("\n              "+_vm._s(_vm.$t('menuLabel.shimen_kw_pc'))+"\n            ")])]):_c('a',{attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"md-submenu__title__parent"},[_c('ul',{staticClass:"md-menu__with-year"},[_c('li',{staticClass:"md-menu__with-year--main"},[_vm._v("\n                  "+_vm._s(_vm.$t('menuLabel.shimen'))+"\n                ")]),_vm._v(" "),_c('li',{staticClass:"md-menu__with-year--sub"},[_vm._v("~1999")])])])])]),_vm._v(" "),_c('ul',{staticClass:"md-submenu",attrs:{"role":"menu"}},[(
              _vm.$$getLoginInfo('L_TAB_SHIMEN') === 'Y' ||
              _vm.$$checkAccessRights('/shimen/')
            )?_c('li',{staticClass:"md-submenu__item disp-sp",class:[_vm.$route.path === '/shimen/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/shimen/')}}},[_c('span',{staticClass:"disp-sp"},[_vm._v(_vm._s(_vm.$t('menuLabel.shimen_kw_sp')))])])]):_vm._e(),_vm._v(" "),(
              _vm.$$getLoginInfo('L_TAB_SHIMEN') === 'Y' ||
              _vm.$$checkAccessRights('/shimen/date/')
            )?_c('li',{staticClass:"md-submenu__item",class:[_vm.$route.path === '/shimen/date/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/shimen/date/')}}},[_c('span',{staticClass:"disp-pc"},[_vm._v(_vm._s(_vm.$t('menuLabel.shimen_date_pc')))]),_c('span',{staticClass:"disp-sp"},[_vm._v(_vm._s(_vm.$t('menuLabel.shimen_date_sp')))])])]):_vm._e(),_vm._v(" "),(
              _vm.$$getLoginInfo('L_TAB_REGION') === 'Y' ||
              _vm.$$checkAccessRights('/shimen/region/')
            )?_c('li',{staticClass:"md-submenu__item",class:[_vm.$route.path === '/shimen/region/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/shimen/region/')}}},[_c('span',{staticClass:"disp-pc"},[_vm._v(_vm._s(_vm.$t('menuLabel.shimen_region_pc')))]),_c('span',{staticClass:"disp-sp"},[_vm._v(_vm._s(_vm.$t('menuLabel.shimen_region_sp')))])])]):_vm._e(),_vm._v(" "),(
              _vm.$$getLoginInfo('L_TAB_OCR_REGION') === 'Y' ||
              _vm.$$checkAccessRights('/shimen/region2/')
            )?_c('li',{staticClass:"md-submenu__item type__beta",class:[_vm.$route.path === '/shimen/region2/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/shimen/region2/')}}},[_c('span',{staticClass:"disp-pc",domProps:{"innerHTML":_vm._s(_vm.$t('menuLabel.shimen_region2_pc'))}}),_vm._v(" "),_c('span',{staticClass:"disp-sp",domProps:{"innerHTML":_vm._s(_vm.$t('menuLabel.shimen_region2_sp'))}})])]):_vm._e(),_vm._v(" "),(
              _vm.$$getLoginInfo('L_TAB_GAICHI') === 'Y' ||
              _vm.$$checkAccessRights('/shimen/gaichi/')
            )?_c('li',{staticClass:"md-submenu__item",class:[_vm.$route.path === '/shimen/gaichi/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/shimen/gaichi/')}}},[_c('span',{staticClass:"disp-pc"},[_vm._v(_vm._s(_vm.$t('menuLabel.shimen_gaichi_pc')))]),_vm._v(" "),_c('span',{staticClass:"disp-sp"},[_vm._v(_vm._s(_vm.$t('menuLabel.shimen_gaichi_sp')))])])]):_vm._e()])]):_vm._e(),_vm._v(" "),(
          _vm.$$getLoginInfo('L_TAB_CHIEZO') === 'Y' ||
          _vm.$$checkAccessRights('/chiezo/')
        )?_c('li',{staticClass:"md-menu__item",class:[_vm.$route.path === '/chiezo/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/chiezo/')}}},[_c('span',[_vm._v(_vm._s(_vm.$t('menuLabel.chiezo')))])])]):_vm._e(),_vm._v(" "),(
          _vm.$$getLoginInfo('L_TAB_CON3') === 'Y' ||
          _vm.$$checkAccessRights('/english/')
        )?_c('li',{staticClass:"md-menu__item",class:[_vm.$route.path === '/english/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/english/')}}},[_c('span',{staticClass:"disp-pc"},[_vm._v(_vm._s(_vm.$t('menuLabel.english_pc')))]),_c('span',{staticClass:"disp-sp"},[_vm._v(_vm._s(_vm.$t('menuLabel.english_sp')))])])]):_vm._e(),_vm._v(" "),(
          _vm.$$getLoginInfo('L_TAB_JINBUTU') === 'Y' ||
          _vm.$$checkAccessRights('/jimbutsu/')
        )?_c('li',{staticClass:"md-menu__item",class:[_vm.$route.path === '/jimbutsu/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/jimbutsu/')}}},[_c('span',[_vm._v(_vm._s(_vm.$t('menuLabel.jimbutsu')))])])]):_vm._e(),_vm._v(" "),(
          _vm.$$getLoginInfo('L_TAB_CON1') === 'Y' ||
          _vm.$$checkAccessRights('/graph/')
        )?_c('li',{staticClass:"md-menu__item",class:[_vm.$route.path === '/graph/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/graph/')}}},[_c('span',[_vm._v(_vm._s(_vm.$t('menuLabel.graph')))])])]):_vm._e(),_vm._v(" "),(
          _vm.$$getLoginInfo('L_TAB_PHOTO') === 'Y' ||
          _vm.$$checkAccessRights('/history/')
        )?_c('li',{staticClass:"md-menu__item is-disabled",class:[_vm.$route.path === '/history/' ? 'is-active' : ''],attrs:{"role":"menuitem"}},[_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePage('/history/')}}},[_c('span',[_vm._v(_vm._s(_vm.$t('menuLabel.history')))])])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }